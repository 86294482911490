// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../../styleguide/components/Paragraph/P.res.js";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Uploader from "../../../../styleguide/forms/Uploader/Uploader.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DatePicker from "../../../../styleguide/forms/DatePicker/DatePicker.res.js";
import * as IconDelete from "../../../../styleguide/icons/IconDelete.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconDocument from "../../../../styleguide/icons/IconDocument.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderProjectProposalFormScss from "./ProviderProjectProposalForm.scss";

var css = ProviderProjectProposalFormScss;

function ProviderProjectProposalForm_UploadProposalDocumentSection(props) {
  var providers = props.providers;
  var setExpirationDate = props.setExpirationDate;
  var selectDocument = props.selectDocument;
  var $$document = props.document;
  var filenameString = $$document !== undefined ? Caml_option.valFromOption($$document).name : props.filename;
  var tmp;
  tmp = props.userRole === "Provider" && providers.length === 1 ? "Upload your proposal as a PDF or Word document on behalf of " + Belt_Array.getExn(providers, 0).name : "Upload a PDF document with your proposal. Include all the necessary information for making a decision in it.";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Upload Your Proposal",
                      className: css.secondaryHeadingText
                    }),
                JsxRuntime.jsx("p", {
                      children: tmp,
                      className: css.paragraphText
                    }),
                filenameString !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx(IconDocument.make, {
                                                size: "XL",
                                                color: "Teal",
                                                className: css.icon
                                              }),
                                          JsxRuntime.jsx(P.make, {
                                                children: filenameString
                                              })
                                        ],
                                        className: css.iconAndNameContainer
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(IconDelete.make, {
                                              size: "LG",
                                              color: "DarkGray",
                                              className: css.icon
                                            }),
                                        className: css.deleteIcon,
                                        onClick: props.removeDocument
                                      })
                                ],
                                className: css.selectedDocumentContainer
                              }),
                          JsxRuntime.jsx("p", {
                                children: "You can upload only one proposal. Delete the file if you want to upload a new one.",
                                className: css.selectedFileNoteText
                              })
                        ]
                      }) : JsxRuntime.jsx(Uploader.make, {
                        allowed: [
                          "Pdf",
                          "Doc",
                          "Docx"
                        ],
                        multiple: false,
                        className: css.uploader,
                        upload: (function (files) {
                            Belt_Array.map(files, (function (file) {
                                    var match = Uploader.FileType.fromMime(file.type);
                                    switch (match) {
                                      case "Jpg" :
                                      case "Png" :
                                      case "Avi" :
                                      case "Mp4" :
                                      case "Mov" :
                                      case "Wmv" :
                                          return ;
                                      default:
                                        return selectDocument(file);
                                    }
                                  }));
                          }),
                        setUploadHandler: (function (param) {
                            
                          }),
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(P.make, {
                                      children: "Drag and Drop the Proposal\n                     or"
                                    }),
                                JsxRuntime.jsx(Button.make, {
                                      size: "SM",
                                      color: "Teal",
                                      children: "Select File"
                                    })
                              ],
                              className: css.uploaderInner
                            })
                      }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h6", {
                              children: "Proposal Expiration Date",
                              className: css.datePickerHeader
                            }),
                        JsxRuntime.jsx(DatePicker.make, {
                              id: "proposal-date-picker",
                              value: Belt_Option.map(props.expirationDate, (function (prim) {
                                      return prim;
                                    })),
                              disabledDays: [{
                                  from: new Date(0, 0),
                                  to: new Date()
                                }],
                              onDayChange: (function (date, param, param$1) {
                                  setExpirationDate(date);
                                })
                            })
                      ],
                      className: css.expirationDateSelectionContainer
                    })
              ]
            });
}

var make = ProviderProjectProposalForm_UploadProposalDocumentSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
